<template>

    <v-card flat
            class="px-3 pt-7">
        <v-card-text class="px-0 headline">
            <h5>{{ name }} {{ surname }}</h5>

            <qriously :value="vcard" :size="size" />
        </v-card-text>
    </v-card>

</template>


<script>
export default {
    props: {
        gender: String,
        name: String,
        surname: String,
        street: String,
        zipcode: String,
        country: String,
        city: String,
        role: String,
        company: String,
        phones: Array,
        mails: Array,
        size: Number,
    },

    data() {
        return {

        }
    },

    computed: {
        vcard() {
            let vcard = 'BEGIN:VCARD\nVERSION:4.0\n'

            if (this.surname || this.name) {
                let pre = ''
                if (this.gender === 'male') {
                    pre = 'Mr.'
                } else if (this.gender === 'female') {
                    pre = 'Ms.'
                }
                vcard += `N:${this.surname};${this.name};;${pre};\n`
                vcard += `FN:${pre} ${this.name} ${this.surname}\n`
            }
            if (this.company) {
                vcard += `ORG:${this.company}\n`
            }
            if (this.role) {
                vcard += `ROLE:${this.role}\n`
            }
            if (this.street || this.city || this.country || this.zipcode) {
                vcard += `ADR;HOME:;;${this.street ? this.street : ''};${this.city ? this.city : ''};;${parseInt(this.zipcode, 10) ? this.zipcode : ''};${this.country ? this.country : ''} \n`
            }

            if (this.phones && this.phones.length > 0) {
                this.phones.forEach(num => {
                    vcard += `TEL;TYPE=work,voice;VALUE=uri:tel:${num}\n`
                })
            }
            if (this.mails && this.mails.length > 0) {
                this.mails.forEach(mail => {
                    vcard += `EMAIL:${mail}\n`
                })
            }

            vcard += 'END:VCARD'

            return vcard
        },
    },

    created() {

    },
}
</script>
